import React from 'react';
import { graphql } from 'gatsby';
import Layout from 'components/layout';
import { LoadingClass, Quick, RespCheck } from 'utils/common';

const Loading = new LoadingClass();
export default class Error extends React.Component {
	componentDidMount() {
		Loading.dataLoaded = true;
		Loading.PostLoaded = () => {
			ErrorFunction.run();
		};

		Loading.Load();
		ErrorFunction.init();
		if (typeof document !== `undefined`) document.body.id = 'error';
	}
	componentWillUnmount() {
		ErrorFunction.reset();
		Loading.Exit();
	}
	render() {
		Loading.MinTimeLoad();
		const errorContent = this.props.data.settingError.frontmatter.error;
		return (
			<Layout titleText={errorContent.title} mainId="error" mainClass="fitheight">
				<section className="full">
					<div id="ErrorContent">OOOOOOOOOOOOOOOOPPS!</div>
					<div>
						<span>{errorContent.footer}</span>
					</div>
				</section>
			</Layout>
		);
	}
}

export const query = graphql`
	query {
		settingError: markdownRemark(frontmatter: { issetting: { eq: true }, contenttype: { eq: "general_setting" } }) {
			frontmatter {
				error {
					title
					footer
				}
			}
		}
	}
`;

const ErrorFunction = {
	animation_id: null,
	initialDelay: 250,
	resizeDelay: 500,
	charSpeed: 50,
	revealed: false,
	ran: false,
	init: () => {
		ErrorFunction.clear();
		ErrorFunction.animatingCount = 0;
		ErrorFunction.resizeAdd();
		document.body.classList.remove('ops_revealed');
	},
	clear: () => {
		if (ErrorFunction.EAnimFrame) cancelAnimationFrame(ErrorFunction.EAnimFrame);
		document.body.classList.remove('ops_animating');
		const opsContainer = Quick.qS('#ErrorContent');
		if (opsContainer) opsContainer.innerHTML = '';
	},
	reset: () => {
		ErrorFunction.clear();
		ErrorFunction.revealed = false;
		ErrorFunction.animatingCount = 0;
		ErrorFunction.resizeRemove();
		document.body.classList.remove('ops_revealed');
	},
	animatingCount: 0,
	run: () => {
		if (!ErrorFunction.ran) {
			ErrorFunction.ran = true;
			//MAIN FUNCTION TO COUTNT AND ANIMATE
			const opsContainer = Quick.qS('#ErrorContent');
			const countO = ErrorFunction.countOPS();

			let renderOPS = 'O'.repeat(countO) + 'PPS!';

			if (ErrorFunction.EAnimFrame) cancelAnimationFrame(ErrorFunction.EAnimFrame);

			// CHECK IF ANIMATION HAS PLAYED
			if (!ErrorFunction.revealed) {
				ErrorFunction.animateOPS(countO, true);
				ErrorFunction.revealed = true;
			} else {
				if (ErrorFunction.animatingCount < 3) {
					// SINCE ANIMATION HAS PLAYED, PLAY ANIMATION WITHOUT DELAY
					document.body.classList.remove('ops_revealed');
					ErrorFunction.animateOPS(countO, false);
				} else {
					// ANIMATION HAS BEEN PLAYED TOO MANY TIMES, RENDER NORMALLY
					document.body.classList.add('ops_revealed');
					opsContainer.innerHTML = renderOPS;
					document.body.classList.remove('ops_animating');
				}
			}
		} else {
			ErrorFunction.reset();
		}
	},
	countOPS: () => {
		let wrapperW,
			wrapperH,
			countX,
			countY,
			windowH = window.innerHeight;

		const opsContainer = Quick.qS('#ErrorContent');
		const lettersize = {
				m: { w: 48, h: 62 },
				d: { w: 96, h: 120 }
			},
			padding = {
				m: { t: 75, b: 50, l: 25, r: 25 },
				d: { t: 100, b: 90, l: 50, r: 50 }
			};

		// //CREATE OOPS
		if (RespCheck.width.mobile()) {
			// ON MOBILE
			// GET WRAPPER SIZE FROM WINDOW SIZE
			wrapperW = window.innerWidth - padding.m.l - padding.m.r;

			if (RespCheck.height.short()) {
				wrapperH = document.body.offsetHeight - padding.m.t - padding.m.b;
			} else {
				wrapperH = windowH - padding.m.t - padding.m.b;
			}

			// GET NUMBER OF ROWS & COLUMNS OF LETTER BASED ON SIZE
			countX = Math.floor(wrapperW / lettersize.m.w);
			countY = Math.floor(wrapperH / lettersize.m.h);

			// ADJUST CONTAINER WIDTH BASED on COUNT
			opsContainer.style.width = (countX * lettersize.m.w + 15).toString() + 'px';

			if (RespCheck.height.short()) {
				if (countY <= 2) {
					countY = 2;
				}
			}

			// anrepeat = repeat + 2;
		} else {
			// ON NOT MOBILE
			// GET WRAPPER SIZE FROM WINDOW SIZE
			wrapperW = window.innerWidth - padding.d.l - padding.d.r;
			wrapperH = windowH - padding.d.t - padding.d.b;

			wrapperH = windowH - padding.d.t - padding.d.b;

			if (RespCheck.height.short()) {
				// wrapperH = document.body.offsetHeight - padding.d.t - padding.d.b;
			} else {
			}

			// GET NUMBER OF ROWS & COLUMNS OF LETTER BASED ON SIZE
			countX = Math.floor(wrapperW / lettersize.d.w);
			countY = Math.floor(wrapperH / lettersize.d.h);
			opsContainer.style.width = (countX * lettersize.d.w + 30).toString() + 'px';

			if (countY <= 2) {
				countY = 2;
			}
		}

		const countO = countX * countY - 3;
		return countO;
	},
	EAnimFrame: null,
	animFramerun: null,
	animateOPS: (countO, withDelay = false) => {
		ErrorFunction.animatingCount++;
		document.body.classList.add('ops_animating');
		const opsContainer = Quick.qS('#ErrorContent');
		opsContainer.innerHTML = '';

		let charSpeed = ErrorFunction.charSpeed;
		//GET TOTAL STEPS with additional 4 CHARACTERS (PPS!)
		const totalSteps = countO + 4;

		//ADJUST CHAR SPEED
		if (totalSteps * charSpeed > 3000) {
			charSpeed = 3000 / totalSteps;
			if (charSpeed < 15) charSpeed = 25;
		}

		// RESET ANIMATION
		ErrorFunction.animFramerun = null;
		if (ErrorFunction.EAnimFrame) cancelAnimationFrame(ErrorFunction.EAnimFrame);
		let currentSteps = 0;

		// SETUP ANIMATION LOOP
		const animationLoop = (timestamp) => {
			// LOG run TIME
			if (!ErrorFunction.animFramerun) ErrorFunction.animFramerun = timestamp;
			// LOG PROGRESS TIME BASED ON run
			const progress = timestamp - ErrorFunction.animFramerun;

			// FUNCTIONS
			// calculate next progress treshold based on current steps
			let nextProgressTresh = (currentSteps + 1) * charSpeed;
			if (withDelay) {
				nextProgressTresh = (currentSteps + 1) * charSpeed + ErrorFunction.initialDelay;
			}

			// check if treshold is meet then increment steps;
			if (progress > nextProgressTresh) {
				currentSteps++;
				if (currentSteps >= totalSteps - 3 && currentSteps <= totalSteps - 2) {
					opsContainer.innerHTML += 'P';
				} else if (currentSteps === totalSteps - 1) {
					opsContainer.innerHTML += 'S';
				} else if (currentSteps === totalSteps) {
					opsContainer.innerHTML += '!';
				} else {
					opsContainer.innerHTML += 'O';
				}
			}

			// LOOP FRAME IF STEPS IS NOT REACHED
			if (currentSteps < totalSteps) {
				ErrorFunction.EAnimFrame = requestAnimationFrame(animationLoop);
			} else {
				document.body.classList.remove('ops_animating');
			}
		};

		// run ANIMATION
		ErrorFunction.EAnimFrame = requestAnimationFrame(animationLoop);
	},
	resizeDelayTimer: null,
	resizeInit: false,
	resize: () => {
		ErrorFunction.clear();
		if (ErrorFunction.resizeDelayTimer != null) clearTimeout(ErrorFunction.resizeDelayTimer);
		ErrorFunction.resizeDelayTimer = setTimeout(() => {
			ErrorFunction.run();
		}, ErrorFunction.resizeDelay);
	},
	resizeAdd: () => {
		if (typeof window !== `undefined`) {
			if (!ErrorFunction.resizeInit) {
				ErrorFunction.resizeInit = true;
				window.addEventListener('resize', ErrorFunction.resize, { passive: true });
			}
		}
	},
	resizeRemove: () => {
		if (typeof window !== `undefined`) {
			ErrorFunction.resizeInit = false;
			window.removeEventListener('resize', ErrorFunction.resize, { passive: true });
		}
	}
};
